<template>
	<div class="users">
		<div class="title">用户信息</div>
		<div class="conten_a">
			<div class="cont_labe">头像</div>
			<div class="cont_imgs">
				<img class="userimg" :src='userdata.face' v-if="userdata.face"/>
				<img v-else class="userimg" src="@/assets/img/user.png" />
				<el-upload
				  class="avatar-uploader"
				  :action="UploadUrl" 
					:headers="headersa" 
					:on-success="success" 
					:show-file-list="false"
					:before-upload="beforeUpload">
				  <div class="upimg">上传头像</div>
				</el-upload>
			</div>
		</div>
		<div class="conten">
			<div class="cont_labes">昵称</div>
			<el-input v-model="userdata.nickName" placeholder="请输入昵称" style="width: 240px;height: 36px;"></el-input>
		</div>
		<div class="conten">
			<div class="cont_labes">姓名</div>
			<el-input v-model="userdata.realName" placeholder="请输入昵称" style="width: 240px;height: 36px;"></el-input>
		</div>
		<div class="conten">
			<div class="cont_labes">性别</div>
			<el-select v-model="userdata.sex" placeholder="请选择性别" style="width: 240px;height: 36px;">
				<el-option label="男" :value="1"></el-option>
				<el-option label="女" :value="0"></el-option>
			</el-select>
		</div>
		<div class="conten">
			<div class="cont_labes">年龄</div>
			<el-input v-model="userdata.age" maxlength="3" placeholder="请输入昵称" style="width: 240px;height: 36px;"></el-input>
		</div>
		<div class="conten">
			<div class="cont_labes">联系电话</div>
			<el-input v-model="userdata.linkPhone" placeholder="请输入昵称" style="width: 240px;height: 36px;"></el-input>
		</div>
		<div class="conten">
			<div class="cont_labes">联系邮箱</div>
			<el-input v-model="userdata.linkEmail" placeholder="请输入昵称" style="width: 240px;height: 36px;"></el-input>
		</div>
		<div class="contens">
			<div class="cont_labes">手机号</div>
			<div class="xiuginput">
				<el-input v-model="userdata.mobile"  :disabled="true" :maxlength='11' style="width: 150px;height: 36px;"></el-input>
				<div class="cont_tex" @click="mobiles">修改</div>
			</div>
			<div class="xiuginput" style="margin-left: 30px;" v-if="!disabd">
				<el-input v-model="userdata.phone" :maxlength='11' placeholder="请输入新手机号" style="width: 150px;height: 36px;"></el-input>
				<div class="cont_texs" v-if="desides">{{texts}}</div>
				<div class="cont_tex" v-else @click="codes">获取验证码</div>
			</div>
		</div>
		<div class="conten" v-if="!disabd">
			<div class="cont_labes">验证码</div>
			<el-input v-model="userdata.code" placeholder="请输入验证码" style="width: 240px;height: 36px;"></el-input>
		</div>
		<div class="buttons_a" @click="modify">确认修改</div>
	</div>
</template>

<script>
	import { oupdet,putNickname,putphone } from "@/request/xie";
	import { getPhoneCode,getUserInfo } from "@/request/homeApi";
	export default{
		name:'users',
		data(){
			return{
				input:'',
				userdata:{},
				UploadUrl:'',
				headersa:{},
				tokens:'',
				disabd:true,
				desides: false,
				numbers: 60,
				texts:'',
				timer:null,
				isfors:false
			}
		},
		mounted() {
			this.UploadUrl=oupdet()
			if(localStorage.getItem('token')){
				this.tokens=localStorage.getItem('token')
				this.headersa={
					accessToken:this.tokens
				}
			}
			this.getuserinfo()
		},
		methods:{
			//获取用户信息
			async getuserinfo(){
				let res=await getUserInfo()
				//console.log(res)
				this.userdata=res.result.member
			},
			//上传图片成功之后
			success(res,file){
				if (res.result) {
					this.userdata.face=res.result
				}
			},
			//上传图片之前
			beforeUpload(file){
				const isJPG =
						file.type === 'image/jpg' ||
						file.type === 'image/JPG' ||
						file.type === 'image/png' ||
						file.type === 'image/PNG' ||
						file.type === 'image/jpeg' ||
						file.type === 'image/JPEG'
				const isLt10M = file.size / 1024 / 1024 < 10
				if (!isJPG) {
					this.$message.error('上传图片只能为jpg或png或jpeg格式')
					return false
				}
				if (!isLt10M) {
					this.$message.error('上传的图片大小不能超过10MB!')
					return false
				}
			},
			//倒计时
			getTime() {
			  this.timer = setInterval(() => {
			    this.numbers--;
			    this.texts = this.numbers + "s";
			    if (this.numbers == 0) {
			      this.desides = false;
			      this.numbers = 60;
			      clearInterval(this.timer);
			    }
			  }, 1000);
			},
			//获取验证码
			codes(){
				if(this.userdata.phone==''){
					this.$message.error('请填写新的手机号')
					return false
				}
				const phones = /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
				if(!phones.test(this.userdata.phone)){
					this.$message.error('请填写正确的手机号')
					return false
				}
				let datas={
					mobile:this.userdata.phone,
					verificationEnums:'EDIT_PHONE'
				}
				getPhoneCode(datas).then((res)=>{
					this.isfors=true
					this.$message.success(res.message)
					this.desides = true;
					this.texts = this.numbers + "s";
					this.getTime();
				})
			},
			//修改手机号
			mobiles(){
				this.disabd=!this.disabd
			},
			//提交信息
			modify(){
				if(this.userdata.nickName==''){
					this.$message.error('请填写昵称')
					return false
				}
				let reg = /^([1-9]\d{0,1}|100)$/;
				if(this.userdata.age!=''&&!reg.test(this.userdata.age)){
					this.$message.error('年龄只能是1-100的数字!')
					return false
				}
				const phonesas = /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
				if(this.userdata.linkPhone!=''&&!phonesas.test(this.userdata.linkPhone)){
					this.$message.error('请填写正确的联系方式')
					return false
				}
				let email = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
				if(this.userdata.linkEmail!=''&&!email.test(this.userdata.linkEmail)){
					this.$message.error('请输入正确的邮箱地址')
					return false
				}
				if(this.disabd){
					let data={
						headImg:this.userdata.face,
						nickName:this.userdata.nickName,
						realName:this.userdata.realName,
						sex:this.userdata.sex,
						age:this.userdata.age,
						linkPhone:this.userdata.linkPhone,
						linkEmail:this.userdata.linkEmail
					}
					putNickname(data).then((res)=>{
						this.$message.success('修改成功')
						this.$router.push({
							path:'/mepages/index?active=0'
						})
					})
				}else{
					let reg = /^([1-9]\d{0,1}|100)$/;
					if(this.userdata.age!=''&&!reg.test(this.userdata.age)){
						this.$message.error('年龄只能是1-100的数字!')
						return false
					}
					const phonesas = /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
					if(this.userdata.linkPhone!=''&&!phonesas.test(this.userdata.linkPhone)){
						this.$message.error('请填写正确的联系方式')
						return false
					}
					let email = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
					if(this.userdata.linkEmail!=''&&!email.test(this.userdata.linkEmail)){
						this.$message.error('请输入正确的邮箱地址')
						return false
					}
					if(!this.isfors){
						this.$message.error('请先获取验证码')
						return false
					}
					if(this.userdata.phone==''||!this.userdata.phone){
						this.$message.error('请填写新的手机号')
						return false
					}
					const phones = /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
					if(!phones.test(this.userdata.phone)){
						this.$message.error('请填写正确的手机号')
						return false
					}
					if(this.userdata.code==''||!this.userdata.code){
						this.$message.error('请填写验证码')
						return false
					}
					let datsa={
						headImg:this.userdata.face,
						nickName:this.userdata.nickName,
						realName:this.userdata.realName,
						sex:this.userdata.sex,
						age:this.userdata.age,
						linkPhone:this.userdata.linkPhone,
						linkEmail:this.userdata.linkEmail,
						code:this.userdata.code,
						mobile:this.userdata.phone
					}
					//console.log(datsa)
					putNickname(datsa).then((res)=>{
						this.$message.success('修改成功,稍后将重新登录')
						this.userdata.mobile=datsa.mobile
						setTimeout(()=>{
							localStorage.clear()
							this.$router.push({
								path:'../../login'
							})
						},2000)
						// this.userdata.phone=''
						// this.userdata.code=''
						// this.desides=false
						// this.disabd=true
						// this.isfors=false
						// this.getuserinfo()
					})
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	.users{
		background-color: #fff;
		box-shadow: 0px 8px 7px 1px rgba(55, 55, 55, 0.01);
		padding-bottom: 20px;
		.title{
			color: #535353;
			font-size: 18px;
			padding: 25px 0 20px 44px;
			box-sizing: border-box;
			border-bottom: 1px solid #F3F3F3;
		}
		.conten_a{
			margin-top: 20px;
			padding-left: 43px;
			display: flex;
			.cont_labe{
				width: 60px;
				font-size: 16px;
				color: #818181;
				text-align: right;
				margin-right: 23px;
				line-height: 68px;
			}
			.cont_imgs{
				display: flex;
				flex-flow: column;
				justify-content: center;
				align-items: center;
				.userimg{
					width: 68px;
					height: 68px;
					border-radius: 50%;
					margin-bottom: 7px;
				}
				.upimg{
					width: 84px;
					height: 30px;
					color: #4CB6E6;
					font-size: 12px;
					text-align: center;
					line-height: 30px;
					border: 1px solid #00A3E0;
					border-radius: 3px;
				}
			}
		}
		::v-deep .conten{
			margin-top: 20px;
			padding-left: 43px;
			display: flex;
			align-items: center;
			.cont_labes{
				width: 80px;
				font-size: 16px;
				color: #818181;
				text-align: right;
				margin-right: 23px;
			}
			.el-input__inner{
				height: 36px;
				color: #535353;
				font-size: 16px;
			}
		}
		::v-deep .contens{
			margin-top: 20px;
			padding-left: 43px;
			display: flex;
			align-items: center;
			.cont_labes{
				width: 80px;
				font-size: 16px;
				color: #818181;
				text-align: right;
				margin-right: 23px;
			}
			.el-input__inner{
				height: 36px;
				color: #535353;
				font-size: 16px;
				border: none;
			}
			 .xiuginput{
				display: flex;
				flex-flow: row;
				justify-content: space-between;
				align-items: center;
				width: 240px;
				border: 1px solid #F0F0F0;
				border-radius: 2px;
				.el-input.is-disabled .el-input__inner{
					cursor:default;
					background-color: #fff;
				}
				.cont_tex{
					cursor: pointer;
					font-size: 14px;
					color: #4CB6E6;
					margin-right: 9px;
				}
				.cont_texs{
					font-size: 14px;
					color: #4CB6E6;
					margin-right: 9px;
				}
			}
		}
		.buttons_a{
			cursor: pointer;
			margin-top: 27px;
			font-size: 16px;
			margin-left: 120px;
			width: 112px;
			text-align: center;
			height: 40px;
			background-color: #00A3E0;
			border-radius: 3px;
			color: #fff;
			line-height: 40px;
		}
	}
</style>
